import React, { useState, useEffect } from "react";
import Nav from "../Nav/index";
import { Row, Col } from "antd";
import Footer from "../FooterSection/index";
import { Link } from "react-router-dom";
import GoToTop from "../External/GoToTop";
import User1 from '../images/user1.jpg';
import User2 from '../images/user2.jpg';
import User3 from '../images/user3.jpg';
import User4 from '../images/user4.jpg';
import {db} from '../firebase/firebase';
import Swal from "sweetalert2";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Checkbox,
  Button,
  AutoComplete
} from "antd";
const { Option } = Select;
const residences = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake"
          }
        ]
      }
    ]
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men"
          }
        ]
      }
    ]
  }
];
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const Aboutus = (props) => {
  const [form] = Form.useForm();
  const [bemail, setBemail] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [problem, setProblem] = useState('');
  const onChangeName = (e) => {
    setName(e.target.value);
  }
  const onChangeBemail = (e) => {
    setBemail(e.target.value);
  }
  const onChangeCity = (e) => {
    setCity(e.target.value);
  }
  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  }
  const onChangeProblem = (e) => {
    setProblem(e.target.value);
  }
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const submitQuery = (e) => {
    e.preventDefault();
    if(bemail === "" || name === "" || city === "" || country === "" || problem === "")
    {
      Swal.fire({
        icon: 'error',
        title: 'Please wait!',
        text: 'Fill all the details!',
        confirmButtonText:'Try again'
      })
      return;
    }
    db.collection("Queries").doc('email').set({
      name: name, 
      BusinessEmail: email, 
      city: city, 
      country: country, 
      BusinessProblem:problem
    })
    .then(() => {
      Swal.fire({
        title: 'Thank you!',
        text: "We'll soon be getting in touch with you!",
        icon: 'success',
        confirmButtonText: 'Okay'
      })
    })
    .catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonText:'Try again'
      })
    })
  }
  const subscribe = (e) => {
    e.preventDefault();
    if(email === "")
    {
      Swal.fire({
        icon: 'error',
        title: 'Please wait!',
        text: 'Fill all the details!',
        confirmButtonText:'Try again'
      })
      return;
    }
    db.collection("Subscribers").doc('email').set({
      email: email
    })
    .then(() => {
      Swal.fire({
        title: 'Done!',
        text: 'You have been successfully added!',
        icon: 'success',
        confirmButtonText: 'Okay'
      })
    })
    .catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonText:'Try again'
      })
    })
  }
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70
        }}
      >
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select
        style={{
          width: 70
        }}
      >
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website
  }));
  return (
    <div className="whole">
      <div className="aboutUs">
        <Nav />
        <div className="aboutSection">
          <div className="container">
            <div className="title">
              <h1>About Us</h1>
            </div>
            <div className="content">
              <div className="content1" style={{ textAlign: "center" }}>
                <p>
                  ListenAI helps Contact Centers to make improve every
                  interaction with help of AI. ListenAI captures every single
                  interaction, analyze customer interaction, behaviour changes,
                  emotion identification whether being Voice or text or video
                  and provides a simple, scalable solution to deliver a bottom
                  line impact.
                </p>
              </div>
              <div className="content2">
                <p>
                  We’re passionate about voices, language, technology and
                  results, and can't wait to find the moments in conversations
                  that delivers the best results to your team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="more">
          <div className="container">
            <div className="further">
              <div className="content">
                <p>
                  We understand the importance of data, its not storage its an
                  asset. At ListenAI, we empower organisation to understand the
                  every interaction being it text, voice or video.
                </p>
                <p>
                  With the help of Artificial intelligence, we transform machine
                  interaction Using AI, NLP, and Machine-Learning, our solutions
                  unlock actionable intelligence to propel organizations towards
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="team">
          <div className="title">
            <h1>Meet the team!</h1>
          </div>
          <div className="people">
            <Row>
              <Col xs={24} sm={12}>
                <div className="containerCard">
                  <div className="team1">
                    <div class="member">
                      <div class="circleImg">
                        <img
                          src={User1}
                          alt="member_image"
                          className="userImg"
                        />
                      </div>
                      <div className="content">
                        <h3 className="name" >
                          Gaurav Gupta{" "}<br className = "optionalBr"/>
                          <a href = "https://www.linkedin.com/in/g-gupta0945/" target = "_blank"><img src="https://img.icons8.com/ios-filled/30/000000/linkedin.png" /></a><br/><span style = {{fontSize:'1.125rem', fontWeight:'bolder', color:"black"}}>Founding Member</span>
                        </h3>
                        <p className="aboutPerson">
                        Gaurav is a graduate from IIT Kharagpur. He is a data science and machine learning enthusiast. He has expertise and previously worked on computer vision and NLP domain. He likes to play with data and bringing out the valuable insights out of them whether it comes to structured or unstructured data.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="containerCard">
                  <div className="team1">
                    <div class="member">
                      <div class="circleImg">
                        <img
                          src={User2}
                          alt="member_image"
                          className="userImg"
                        />
                      </div>
                      <div className="content">
                        <h3 className="name">
                          Videet Nimsarkar{" "}<br className = "optionalBr"/>
                          <a href = "https://www.linkedin.com/in/videet-nimsarkar-2781211bb/" target = "_blank"><img src="https://img.icons8.com/ios-filled/30/000000/linkedin.png" /></a><br/><span style = {{fontSize:'1.125rem', fontWeight:'bolder', color:"black"}}>Founding Member</span>
                        </h3>
                        <p className="aboutPerson">
                        Videet is a graduate from IIT Kharagpur. He posses exceptional skills in machine learning, deep learning to deliver bottom line impact. His interest includes predictive data modelling, NLP and solving real world problems. Videet is also an avid badminton player.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div style={{ paddingTop: "2%" }}></div>
            <Row>
              <Col xs={24} sm={12}>
                <div className="containerCard">
                  <div className="team1">
                    <div class="member">
                      <div class="circleImg">
                        <img
                          src={User3}
                          alt="member_image"
                          className="userImg"
                        />
                      </div>
                      <div className="content">
                        <h3 className="name">
                          Sayli Wasnik{" "}<br className = "optionalBr"/>
                          <a href = "https://www.linkedin.com/in/sayli-wasnik-853b91b8/" target = "_blank"><img src="https://img.icons8.com/ios-filled/30/000000/linkedin.png" /></a>
                        </h3>
                        <p className="aboutPerson">
                        Sayli is a graduate from NIT Jamshedpur. She is an experienced Full-stack Web Developer and has worked on multiple projects with expertise in Javascript and Python. She has a good eye for design and is also an avid reader.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="containerCard">
                  {/* <div className="team1">
                    <div class="member">
                      <div class="circleImg">
                        <img
                          src={User4}
                          alt="member_image"
                          className="userImg"
                        /> */}
                      {/* </div> */}
                      {/* <div className="content">
                        <h3 className="name">
                          Siddharth.S.Chandran{" "}<br className = "optionalBr"/>
                          <a href = "https://www.linkedin.com/in/siddharth-s-chandran-1b96311b9/" target = "_blank"><img src="https://img.icons8.com/ios-filled/30/000000/linkedin.png" /></a>
                        </h3>

                        <p className="aboutPerson">
                        Siddharth is a computer science undergraduate at VIT university. He is a full-stack developer, experienced with MERN stack. He has worked  on automated parking allotment app(AutoParker) and interesting projects in automation. His interest includes Data analytics and web development.
                        </p>
                      </div> */}
                    {/* </div> */}
                  {/* </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="ready">
          <div className="container">
            <div className="query">
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={14}>
                  <div className="para">
                    <div className="title">
                      <h1>Ready to experience ListenAI!</h1>
                    </div>
                    <div className="content">
                      <p>
                        We believe in the power of data, that drives businesses.
                        At ListenAI, we aim to help contact center become more
                        Efficient and deliver better customer outcome
                      </p>
                      <p className="questions">
                        Questions? We would be happy to help you!
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={10}>
                  <div className="form">
                    <Form
                      {...formItemLayout}
                      form={form}
                      name="register"
                      onFinish={onFinish}
                      initialValues={{
                        residence: ["zhejiang", "hangzhou", "xihu"],
                        prefix: "86"
                      }}
                      scrollToFirstError
                      layout="vertical"
                      requiredMark={false}
                    >
                      <Form.Item
                        name="name"
                        label={<label>Your Name</label>}
                        rules = {[{required:true}]}
                      >
                        <Input onChange = {onChangeName}/>
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Business E-mail"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!"
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!"
                          }
                        ]}
                      >
                        <Input onChange = {onChangeBemail}/>
                      </Form.Item>
                      <Form.Item
                        name="city"
                        label="City"
                      >
                        <Input onChange = {onChangeCity}/>
                      </Form.Item>
                      <Form.Item
                        name="country"
                        label="Country"
                      >
                        <Input onChange = {onChangeCountry}/>
                      </Form.Item>
                      <Form.Item
                        name={["Any specific", " business problem"]}
                        label="Any specific business problem"
                      >
                        <Input.TextArea onChange = {onChangeProblem}/>
                      </Form.Item>
                      <Form.Item>
                        <div className="btnSpace">
                          <Button block className="submit" onClick = {submitQuery}>
                            Submit
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="subscribe">
            <div className="loop">
              <div className="title">
                <h1>Want to hear from us?</h1>
              </div>
              <div className="content">
                <p>
                  We are available at{" "}
                  <a href="mailto:contact@listenai.in" style = {{fontWeight:"bolder"}}>contact@listenai.in</a>
                </p>
                <p>Subscribe to our news letter!</p>
                <div class="email-form">
                  <div>
                    <input
                      type="text"
                      class="email-input"
                      placeholder="Your E-mail Id"
                      onChange = {onChangeEmail}
                    />
                  </div>
                  <div class="email-button">
                    <button class="ripple" onClick = {subscribe}>Subscribe</button>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="tryOut1">
          <div className="container">
            <div className="demoTry">
              <div className="title">
                <h1>Try our speech analytics and text analytics right away!</h1>
              </div>
              <div className="content">
                <Link to="/product">
                  <button className="demo">Get a demo</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <GoToTop />
    </div>
  );
};
export default Aboutus;
