import React from 'react';
import GoToTop from '../External/GoToTop';
import FooterSection from '../FooterSection';
import Nav from '../Nav/index';
const Terms = (props) => {
    return (
        <div>
            <div >
                <Nav />
            </div>
            <div style={{ paddingTop: '2%' }}></div>

            <article>
                <div className = "topHeading">
                    <h1>Terms and Conditions</h1>
                </div>
                <date></date>
                <p>Please read these terms of service (“terms of service”, “terms”) carefully
                    before using listenai.in website (“website”, “service”) operated by ListenAI
                    (“us”, ‘we”, “our”).
                </p>
                <hr class="article-divider" />
                <h3>
                    Conditions of use
                </h3>
                <date></date>
                <p>By using this website, you certify that you have read and reviewed this
                    Agreement and that you agree to comply with its terms. If you do not want to
                    be bound by the terms of this Agreement, you are advised to leave the website
                    accordingly. ListenAI only grants use and access to this website, its products,
                    and its services to those who have accepted its terms.
                </p>
                <hr className="article-divider" />
                <h3>
                    Privacy Policy
                </h3>
                <date></date>
                <p>Before you continue using our website, we advise you to read our privacy
                    policy regarding our user data collection. It will help you better understand
                    our practices
                </p>
                <hr className="article-divider" />
                <h3>
                    Intellectual Property
                </h3>
                <date></date>
                <p>You agree that all materials, products, and services provided on this website
                    are the property of ListenAI, its affiliates, directors, officers, employees,
                    agents, suppliers, or licensors including all copyrights, trade secrets,
                    trademarks, patents, and other intellectual property. You also agree that you
                    will not reproduce or redistribute the ListenAI’s intellectual property in any
                    way, including electronic, digital, or new trademark registrations
                </p>
                <p>You grant ListenAI a royalty-free and non-exclusive license to display, use,
                    copy, transmit, and broadcast the content you upload and publish. For issues
                    regarding intellectual property claims, you should contact the company in
                    order to come to an agreement.
                </p>
                <hr className="article-divider" />
                <h3>
                    User Accounts
                </h3>
                <date></date>
                <p>As a user of this website, you may be asked to register with us and provide
                    private information. You are responsible for ensuring the accuracy of this
                    information, and you are responsible for maintaining the safety and security
                    of your identifying information. You are also responsible for all activities that
                    occur under your account or password.

                </p>
                <p>
                    If you think there are any possible issues regarding the security of your
                    account on the website, inform us immediately so we may address it
                    accordingly
                </p>
                <p>
                    We reserve all rights to terminate accounts, edit or remove content and cancel
                    orders in their sole discretion.

                </p>
                <hr className="article-divider" />
                <h3>
                    Applicable law
                </h3>
                <date></date>
                <p>By visiting this website, you agree that the laws of the US without regard to
                    principles of conflict laws, will govern these terms and conditions, or any
                    dispute of any sort that might come between ListenAI and you, or its business
                    partners and associates.

                </p>
                <hr className="article-divider" />
                <h3>
                    Disputes
                </h3>
                <date></date>
                <p>Any dispute related in any way to your visit to this website or to products you
                    purchase from us shall be arbitrated by state or federal court US and you
                    consent to exclusive jurisdiction and venue of such courts.


                </p>
                <hr className="article-divider" />
                <h3>
                    Indemnification
                </h3>
                <date></date>
                <p>You agree to indemnify ListenAI and its affiliates and hold ListenAI harmless
                    against legal claims and demands that may arise from your use or misuse of
                    our services. We reserve the right to select our own legal counsel.



                </p>
                <hr className="article-divider" />
                <h3>
                    Limitation on liability
                </h3>
                <date></date>
                <p>
                    ListenAI is not liable for any damages that may occur to you as a result of
                    your misuse of our website.
                </p>
                <p>ListenAI reserves the right to edit, modify, and change this Agreement any
                    time. We shall let our users know of these changes through electronic mail.
                    This Agreement is an understanding between ListenAI and the user, and this
                    supersedes and replaces all prior agreements regarding the use of this
                    website.


                </p>
            </article>

            <div style={{ paddingTop: '2%' }}></div>

            <FooterSection />
            <GoToTop />
        </div>
    )
}
export default Terms;