import { Button, Menu, Layout, Dropdown } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import Logo from "../images/logo.png";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  MenuOutlined
} from "@ant-design/icons";

const Nav = (props) => {
  return (
    <div className="horizontal-nav-container">
      <Menu
        mode="horizontal"
        defaultSelectedKeys="3"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <div className="horizontal-nav">
          <div>
            <Menu.Item className = "logoTitle" style = {{paddingLeft:'0%'}}>
              <Link to="/" style={{  fontWeight: 'bolder', fontSize: '1.5rem', color:'black', fontFamily:"Font Name" }}>
                <img src={Logo} className="logoPic1" /> ListenAI
              </Link>
            </Menu.Item>
          </div>
          <div className="horizontal-nav-right" style = {{float:'right'}}>
            <div className="horizontal-nav-expanded" >
              <Menu.Item key="product">
                <Link to="/product" style={{fontWeight: 'bolder',fontSize:'1.2rem', color:'black',fontFamily:"Font Name" }}>Product</Link>
              </Menu.Item>
              <Menu.Item key="resource">
                <Link to="/resource" style={{fontWeight: 'bolder',fontSize:'1.2rem', color:'black',fontFamily:"Font Name" }}>Resource</Link>
              </Menu.Item>
              <Menu.Item key="about-us">
                <Link to="/about" style={{fontWeight: 'bolder',fontSize:'1.2rem', color: 'black',fontFamily:"Font Name" }}>About Us</Link>
              </Menu.Item>
              <Menu.Item className = "logIn">
              <Link to="/login" style={{fontWeight: 'bolder',fontSize:'1.2rem', color:'black',fontFamily:"Font Name" }}>Log In</Link>
            </Menu.Item>
            </div>

            <div className="horizontal-nav-dropdown" style={{ color: "black", fontSize:'1.2rem' }}>
              <Dropdown className = "drpDown"
                overlay={
                  <Menu>
                    <Menu.Item key="product">
                      <Link to="/product" style={{ color: 'black', fontSize:'1.2rem' }}>Product</Link>
                    </Menu.Item>
                    <Menu.Item key="resource">
                      <Link to="/resource" style={{ color: 'black', fontSize:'1.2rem' }}>Resource</Link>
                    </Menu.Item>
                    <Menu.Item key="about-us">
                      <Link to="/about" style={{ color: 'black', fontSize:'1.2rem' }}>About Us</Link>
                    </Menu.Item>
                      <Menu.Item >
                        <Link to="/login" style={{ color: 'black', fontSize:'1.2rem' }}>Log In</Link>
                      </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                overlayStyle={{
                  width: "160px"
                }}
              >
                <MenuOutlined />
              </Dropdown>
            </div>
          </div>
          <div >
            <Menu.Item className = "getADemoLink">
              <Link to="/login" style={{fontFamily:"Font Name"  }}><button className ="getADemo1">Get a demo</button></Link>
            </Menu.Item>
            
          </div>

        </div>
      </Menu>
    </div>
  );
};

export default Nav;