import React from 'react';
import FooterSection from '../FooterSection';
import Nav from '../Nav/index';
const Page1 = (props) => {
    return (
        <div>
            <div>
                <Nav />
            </div>
            <div style = {{paddingTop:'2%'}}></div>
            <article>
                <h1>
                Contact Center Analytics – Empower your business at scale
                </h1>
                <p>
                Probably the most evident use of Natural Language Processing (NLP) is with automated attendants like Apple's Siri and Amazon's Alexa. However, contact centers have also been on the forefront of using Natural Language Processing (NLP) to deliver very tangible business and customer experience benefits. 
                </p>
                <p>Interactive voice response (IVR) systems commonly use Natural Language Processing (NLP) to enable customers to interact with menus using natural speech, as well as facilitate self-service transactions. Additionally, speech analytics tools can comb through a multitude of interaction recordings to create transcripts, identify common call drivers, flag potential compliance issues, and more. </p>
                <p>As Natural Language Processing (NLP) continues to mature and improve, it's likely that additional contact center applications will emerge.</p>
                <hr class="article-divider" />
            </article>

            <article>
                <h3>
                How call center efficiency affected?
                </h3>
                <date></date>
                <p>
                There are many factors at play in most call centers that could adversely affect their performance. Here are a few common causes for concern in call centers worldwide: </p>
                <ul>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Poor cooperation</span> - Cooperation between agents is integral to their long-term success in dealing with difficult customer questions and concerns.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Excessive turnover</span> - This can lead to lower morale among remaining members of your workforce as both cooperation and cohesion are sidetracked by a revolving door of new hires.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Inaccurate metrics</span> - Bad metrics that misrepresent performance can cause all manner of mayhem for your team and derail improvement efforts. Tracking the right metrics can help to boost business performance and efficiency.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Outdated tools</span> - Bad tools make it impossible for agents to demonstrate meaningful improvement over time</p></li>
                </ul>
                <hr class="article-divider" />
            </article>
            <div style = {{paddingTop:'2%'}}></div>

            <FooterSection/>
        </div>
    )
}
export default Page1;