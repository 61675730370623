import React from 'react';
import FooterSection from '../FooterSection';
import Nav from '../Nav/index';
const Page1 = (props) => {
    return (
        <div>
            <div >
                <Nav />
            </div>
            <div style = {{paddingTop:'2%'}}></div>

            <article>
                <h3>
                How does speech analytics useful for businesses?
                </h3>
                <date></date>
                <p>Deploying a voice analytics software is incredibly beneficial for any contact center. With proper and targeted use, companies can resolve even the trickiest issues very quickly. On a side note, contact center AI uses automatic speech recognition and natural language processing (NLP) to transcribe and analyze 100% agent interactions.</p>
                <ul>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Ensures regulatory compliance:</span> By identifying the use of specific keywords in the transcripts, voice analytics monitors process adherence and compliance for individual agents.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Gauges agent performance:</span> Voice analytics software gauges aspects like sentiment and hold times providing insights into the quality of an agent.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Analyses response to new policies:</span> The tone of voice of a customer’s questions and their general responses aids in assessing the effectiveness of let’s say, a new brand campaign or change in company policy.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Records complaints:</span> Voice analytics highlights reasons behind customers unhappiness and provides solutions to fix their problems.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Find new product ideas:</span> If there’s a demand for new products and services, voice analytics can scan customer perspectives using speech and tone analysis.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Edge over competition:</span> Analyzing customer responses or complaints is useful in determining competitor strategy.</p></li>
                    <li><p><span style = {{fontWeight:'bolder', fontSize:'1.5rem'}}>Cost effective:</span> Reliance on voice analysis negates the need for managers and quality assurance teams to manually perform transcription tasks, saving massive amount of time and costs incurred by the company</p></li>
                </ul>
                <p>Voice analytics reduces the manual work of a QA and ensures high accuracy, speed and increased efficiency. With a speech to text software with excellent transcription accuracy, it can be a game changer for any contact center and an edge over your competitors in building a world class customer experience.</p>
                <hr class="article-divider" />
            </article>
            <div style = {{paddingTop:'2%'}}></div>

            <FooterSection/>
        </div>
    )
}
export default Page1;