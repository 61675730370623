import { Carousel, Col, Row } from "antd";
import React from "react";
import { useMedia } from "react-use";

const data = [
  {
    key: "Sentiment Analysis",
    title: (
      <h3 class="cards__title" style={{ fontWeight: "bolder" }}>
        Sentiment <br className="optBreak" />
        Analysis
      </h3>
    ),
    text: "Learn what drives a positive or negative customer experience. Scale the best practices top agents use with their customers."
  },
  {
    key: "AI Powered Moments",
    title: (
      <h3 class="cards__title" style={{ fontWeight: "bolder" }}>
        AI Powered Moments
      </h3>
    ),
    text: "Automatically surface interactions to review. Dig deeper into areas like supervisor escalation, dead air and hold time."
  },
  {
    key: "Identify Growth",
    title: (
      <h3 class="cards__title" style={{ fontWeight: "bolder" }}>
        Identify <br className="optBreak" />
        Growth
      </h3>
    ),
    text: "With the help of NLP and AI translating insights into revenue in order to drive expansion and increase conversion rates."
  }
];

const ProductOffer = () => {
  const isMobile = useMedia("(max-width: 576px)");
  const isMediumScreen = useMedia("(max-width: 991px)");

  return (
    <div className="options">
      <div className="container">
        <div className="title">
          <h1>We offer!</h1>
        </div>
        <div className="offers">
          {!isMobile ? (
            <div className="cards">
              <Row gutter={[28, 28]}>
                {data.map((cardItem, i) => (
                  <Col
                    key={cardItem.key}
                    sm={24}
                    md={12}
                    lg={8}
                    offset={i === 2 && isMediumScreen ? 6 : 0}
                  >
                    <div class="cards__item">
                      {cardItem.title}
                      <p>{cardItem.text}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Carousel autoplay>
              {data.map((cardItem) => (
                <div className="slide">
                  <div class="cards__item">
                    {cardItem.title}
                    <p>{cardItem.text}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductOffer;
