import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import { auth, firebase } from "../firebase/firebase";
import Swal from "sweetalert2";
import { Col, Row } from "antd";
const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };
  const login = (e) => {
    e.preventDefault();
    if(email === "" || password === "")
    {
      Swal.fire({
        icon: 'error',
        title: 'Please wait!',
        text: 'Please fill all the fields',
      })
      return;
    }
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        console.log("Success");
      })
      .catch((error) => {
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/user-not-found"
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Please try again!',
            text: 'User not found',
          })
        } else if (error.code === "auth/wrong-password") {
          Swal.fire({
            icon: 'error',
            title: 'Please try again!',
            text: 'Wrong Password',
          })
        }
      });
  };
  const forgot = (e) => {
    e.preventDefault();
    if(email === "")
    {
      Swal.fire({
        icon: 'error',
        text: 'Please enter your email',
      });
      return;
    }
    else 
    {
      auth.sendPasswordResetEmail(email);
    Swal.fire({
      icon: 'Success',
      title: 'Sent',
      text: 'We have sent you recovery option through your mail',
    })
    }
  }
  const gAuth = (e) => {
    e.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((re) => {
        console.log(re);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Please try again!',
          
        })
      });
  };
  return (
    <div className="formPage">
      <div className="formSection">
        <div className="container1">
          <div>
            <Link to="/" style={{ color: "black" }}>
              <img src={Logo} className="logoPicture" /> ListenAI
            </Link>
          </div>
        </div>
        <div class="container">
          <div class="formbg">
            <div class="formbg-inner">
              <div class="title">Sign In to your account</div>
              <form id="stripe-login" onChange={onChangeInput}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <div class="field">
                      <label for="email">Email</label>
                      <input type="email" name="email" />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <div class="grid--50-50">
                        <label for="password">Password</label>
                      </div>
                      <input type="password" name="password" />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field field-checkbox flex-flex align-center">
                      <label for="checkbox">
                        <a href="#" style={{ color: "blue" }} onClick = {forgot}>
                          Forgot password?
                        </a>
                      </label>
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <input
                        type="submit"
                        name="submit"
                        value="Sign In"
                        onClick={login}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div
                      class="field"
                      style={{ textAlign: "center", fontSize: "1.25rem" }}
                    >
                      Sign In with :{" "}
                      <button className="google" onClick={gAuth}>
                        <img src="https://img.icons8.com/fluency/48/000000/google-logo.png" />
                      </button>{" "}
                      {/*Linkedin disabled as it requires blaze plan */}
                      {/*<button class="linkedin">
                        <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
  </button>*/}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          <div className="bottom-link">
            <span>
              Don't have an account?{" "}
              <Link to="/signup" style={{ color: "blue" }}>
                Sign Up
              </Link>
            </span>
          </div>
          <div class="footer-link">
            <div class="listing padding-top--24 padding-bottom--24 flex-flex center-center">
              <span>
                <a href="#">© ListenAI</a>
              </span>
              <span>
                <Link to ="/privacy"><a href="#">Privacy & terms</a></Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
