import React, { useEffect } from "react";
import Nav from "../Nav/index";
import Article1 from "../images/Article1.svg";
import Article2 from '../images/article2.svg';
import { Row, Col } from "antd";
import FooterSection from "../FooterSection";
import { Link } from "react-router-dom";
import GoToTop from "../External/GoToTop";
const Res = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="topMost">
        <Nav />
        <div className="resFirst">
            <div className="sec1">
              <div className="title">
                <h1>
                  Data is not a storage, its an asset that drives business!
                </h1>
              </div>
              <div className="content" style={{ textAlign: "center" }}>
                <p>
                  Contact Center AI provides a number of ways to improve
                  performance monitoring for the thousands of interactions
                  taking place per day.
                </p>
                <p>
                  This means you need to be tracking metrics and APIs on a wide
                  variety of interaction types, which in turn uncovers and
                  delivers deep actionable insights to drive improvements
                </p>
              </div>
            </div>
        </div>
        <div className="resSecond">
          <div className="articles a">
            <div className="container">
              <Row>
                <Col xs={24} sm={12}>
                  <div className="articleImg">
                    <img src={Article1} />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="articleContent">
                    <div className="heading">
                      <h1>
                        Contact Center Analytics – Empower your business at
                        scale
                      </h1>
                    </div>
                    <div className="body">
                      <p>
                        Probably the most evident use of Natural Language
                        Processing (NLP) is with automated attendants like
                        Apple's Siri and Amazon's Alexa. However, contact
                        centers have also been on the forefront of using Natural
                        Language Processing (NLP) to deliver very tangible
                        business and customer experience benefits
                      </p>
                      <Link to="/blog1">
                        <button className="readMore">Read more...</button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="articles dark b">
            <div className="container">
              <Row className="parent">
                <Col xs={24} sm={12}>
                  <div className="articleContent">
                    <div className="heading">
                      <h1>How does speech analytics useful for businesses?</h1>
                    </div>
                    <div className="body">
                      <p>
                        Deploying a voice analytics software is incredibly
                        beneficial for any contact center. With proper and
                        targeted use, companies can resolve even the trickiest
                        issues very quickly. On a side note, contact center AI
                        uses automatic speech recognition and natural language
                        processing (NLP){" "}
                      </p>
                      <Link to="/blog2">
                        <button className="readMore">Read more...</button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="articleImg">
                    <img src={Article2} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="tryOut">
            <div className="trySection">
              <h1>Help your customer fall in love again!</h1>
              <h2>Ready to experience ListenAI!</h2>
              <Link to="/product">
                <button className="demo1">Try our demo now!</button>
              </Link>
            </div>
          </div>
        </div>
        <FooterSection />
      </div>
      <GoToTop />
    </div>
  );
};
export default Res;
