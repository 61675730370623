import { Col, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import { auth } from "../firebase/firebase";
import { firebase } from "../firebase/firebase";
import {db} from '../firebase/firebase';
import Swal from "sweetalert2";
const Signup = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [verPas, setVerPas] = useState("");
  const [uid, setUid] = useState("");
  const [err, setErr] = useState("");
  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "verPas") {
      setVerPas(value);
    }
  };
  const register = (e) => {
    e.preventDefault();
    if (verPas != password) {
      window.alert("Passwords are not matching!");
      return;
    }
    if(name === "" || email === "" || password === "")
    {
      Swal.fire({
        icon: 'error',
        title: 'Please wait',
        text: 'Enter all the fields',
        confirmButtonText:'Try again'
      })
      return;
    }
    
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        db.collection('Users').doc(user.user.uid).set({
          name: name, 
          email: email, 
          userId: user.user.uid
        });

      })
      .catch((err) => {
        
        switch (err.code) {
          case "auth/email-already-in-use":
          case "auth/invalid-email":
            Swal.fire({
              icon: 'error',
              title: 'Please wait',
              text: err.message,
              confirmButtonText:'Try again'
            })
            break;
          case "auth/weak-password":
            Swal.fire({
              icon: 'error',
              title: 'Please wait!',
              text: 'Enter a stronger password',
              confirmButtonText:'Try again'
            })
            break;
          default:
            console.log("Hello");
        }
      });
  };
  const gAuth = (e) => {
    e.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((re) => {
        console.log(re);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const lAuth = (e) => {
    e.preventDefault();
  };
  const passwordVerification = (e) => {
    if (e.target.value != password) {
      setErr("Passwords don't match");
    } else {
      setErr("Passwords are matching");
    }
  };
  return (
    <div className="formPage">
      <div className="formSection">
        <div className="container1">
          <div>
            <Link to="/" style={{ color: "black" }}>
              <img src={Logo} className="logoPicture" /> ListenAI
            </Link>
          </div>
        </div>
        <div class="container">
          <div class="formbg">
            <div class="formbg-inner">
              <div class="title">Sign Up to your account</div>
              <form id="stripe-login" onChange={onChangeInput}>
                <Row gutter={[24, 24]}>
                  <Col xs={24}>
                    <div class="field">
                      <label for="email">Name</label>
                      <input type="email" name="name" />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <label for="email">Email</label>
                      <input type="email" name="email" />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <label for="password">Password</label>
                      <input type="password" name="password" />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <label for="password">Confirm Password</label>
                      <input
                        type="password"
                        name="verPas"
                        onChange={passwordVerification}
                      />
                    </div>
                    {err ? (
                      <div class="field">
                        <div
                          style={{
                            color:
                              err === "Passwords don't match" ? "red" : "green",
                            fontSize: "1rem"
                          }}
                        >
                          {err}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={24}>
                    <div class="field">
                      <input
                        type="submit"
                        name="submit"
                        value="Sign Up"
                        onClick={register}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div
                      class="field"
                      style={{ textAlign: "center", fontSize: "1.25rem" }}
                    >
                      Sign Up with :{" "}
                      <button className="google" onClick={gAuth}>
                        <img src="https://img.icons8.com/fluency/48/000000/google-logo.png" />
                      </button>{" "}
                      {/*LinkedIn disabled as it requires blaze plan*/}
                      {/*<button class="linkedin" onClick={lAuth}>
                        <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
                        </button>*/}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          <div className="bottom-link">
            <span>
              Already have an account?{" "}
              <Link to="/login" style={{ color: "blue" }}>
                Sign In
              </Link>
            </span>
          </div>
          <div class="footer-link">
            <div class="listing padding-top--24 padding-bottom--24 flex-flex center-center">
              <span>
                <a href="#">© ListenAI</a>
              </span>
              <span>
                <Link to ="/privacy"><a href="#">Privacy & terms</a></Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;
