import { Col, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import {db} from '../firebase/firebase';
import Swal from 'sweetalert2'
const FooterSection = () => {
  const [email, setEmail] = useState('');
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const subscribe = (e) => {
    e.preventDefault();
    if(email === "")
    {
      Swal.fire({
        icon: 'error',
        title: 'Please wait!',
        text: 'Fill all the details!',
        confirmButtonText:'Try again'
      })
      return;
    }
    db.collection("Subscribers").doc('email').set({
      email: email
    })
    .then(() => {
      Swal.fire({
        title: 'Done!',
        text: 'You have been successfully added!',
        icon: 'success',
        confirmButtonText: 'Okay'
      })
    })
    .catch(() => {
      window.alert('Please try again!');
    })
  }
  return (
    <div className="footer">
      <div className="container">
        <Row gutter={[20, 16]}>
          <Col xs={24} lg={6}>
            <div className="col1">
              <div className="title">
                <h1>
                  <Link to = "/" style = {{color:'white'}}><img src={Logo} className="logoPic" /> ListenAI</Link>
                </h1>
              </div>
              <div className="content">
                <p>
                  ListenAI helps contact centers to make improve every
                  interaction with help of AI. ListenAI captures every single
                  interaction, analyze customer interaction, behaviour changes,
                  emotion identification whether being voice or text or video
                  and provides a simple, scalable solution to deliver a bottom
                  line impact.
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={8} lg={6}>
            <div className="col2">
              <div>
                <div className="title">
                  <h1>Explore</h1>
                </div>
                <div className="content">
                  <ul>
                    <Link to="/">
                      <li className="links">
                        <a>Home</a>
                      </li>
                    </Link>
                    <Link to="/product">
                      <li className="links">
                        <a>Product</a>
                      </li>
                    </Link>
                    <Link to="/resource">
                      <li className="links">
                        <a>Resource</a>
                      </li>
                    </Link>
                    <Link to="/about">
                      <li className="links">
                        <a>About Us</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={8} lg={6}>
            <div className="col2">
              <div>
                <div className="title">
                  <h1>Legal</h1>
                </div>
                <div className="content">
                  <ul>
                    <Link to="/privacy">
                      <li className="links">
                        <a>Privacy Policy</a>
                      </li>
                    </Link>
                    <Link to="/terms">
                      <li className="links">
                        <a>Terms & conditions</a>
                      </li>
                    </Link>
                    <div>
                      <a
                        href="https://www.linkedin.com/company/listenai/"
                        target="_blank"
                      >
                        <img
                          src="https://img.icons8.com/ios-glyphs/35/000000/linkedin.png"
                          style={{ filter: "invert(100%)" }}
                        />
                      </a>{" "}
                      <a
                        href="https://www.facebook.com/listenai"
                        target="_blank"
                      >
                        <img
                          src="https://img.icons8.com/material-rounded/30/000000/facebook-new.png"
                          style={{ filter: "invert(100%)" }}
                        />{" "}
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8} lg={6}>
            <div className="col3">
              <div>
                <div className="title">
                  <h1> Subscribe to our newsletter</h1>
                </div>
                <div className="content">
                  <input
                    type="text"
                    className="emailInp"
                    placeholder="E-mail Id"
                    onChange = {onChangeEmail}
                  />
                  <div className="buttonSpace">
                    <button className="subscribeBtn" onClick = {subscribe}>Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="finalFooter">
          <p>© 2021 ListenAI| All rights reserved | Made with ❤️</p>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
