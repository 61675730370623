import React, { useState, useEffect } from "react";
import Nav from "../Nav";
import Side1 from "../images/side.gif";
import Side from "../images/side.svg";
import Sound from "../images/sound.svg";
import Wave from "../images/wave1.svg";
import Wave0 from "../images/wave2.svg";
import Dash from '../images/tab.jpg';
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Radio,
  Slider,
  Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col,
  Carousel,
  Input,
} from "antd";

import {
  MoneyCollectOutlined,
  FieldTimeOutlined,
  ArrowUpOutlined,
  UserOutlined,
  AimOutlined,
} from "@ant-design/icons";
import FooterSec from "../FooterSection/index";
import { UploadOutlined, InboxOutlined, DownOutlined } from "@ant-design/icons";
import First from "../images/first-cropped.svg";
import { Footer } from "antd/lib/layout/layout";
import GoToTop from "../External/GoToTop";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ProductOffer from "./Offer";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 22,
  },
};

const normFile = (e) => {
  console.log("Upload event:", e);

  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

const Product = ({ props }) => {
  const [form] = Form.useForm();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [file, setFile] = useState(false);
  const [text, setText] = useState("");
  const [mike, setMike] = useState(false);
  const [status, setStatus] = useState("deactivated");
  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
    setText(transcript);
  };
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const onFile = (e) => {
    SpeechRecognition.stopListening();
    e.preventDefault();
    if (file) setFile(true);
    else setFile(false);
  };
  const onMike = (e) => {
    e.preventDefault();
    if (mike) setMike(false);
    else setMike(true);
  };
  const submit = (e) => {
    e.preventDefault();
    SpeechRecognition.stopListening();
    setText(transcript);
    document.querySelector("textarea").innerHTML = transcript;
  };
  return (
    <div>
      <div className="onProduct">
        <div className="navClass">
          <Nav />
        </div>
        <div className="firstSection">
          <div style={{ paddingTop: "1%" }}></div>
          <Row>
            <Col xs={24} sm={12}>
              <div className="writtenPart">
                <div className="title">
                  <h1>Want to explore more of customer analytics?</h1>
                </div>
                <hr />
                <div className="content">
                  <p>
                    Bring the power of data to your organization. At ListenAI,
                    we analyse every interaction, deliver deep insights that
                    boost performance of your team.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ textAlign: "center" }}>
                <img src={Side1} className="sideGif" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="option">
        <div className="container">
          <div className="title">
            <h1>Worried about the language barrier??</h1>
          </div>
          <div className="content">
            <p>Now ListenAI works in Hindi too!!</p>
            <button className="demo">Try it now!</button>
          </div>
        </div>
      </div>
      <div className="howWorks">
        <div className="working">
          <div className="title">
            <h1>How it works?</h1>
          </div>
          <div className="content">
            <Row>
              <Col xs={24}  sm={window.innerWidth > 1024 ? 8 : 24}>
                <div className="input">
                  
                  <div className="desc">
                    <div className="imageCont">
                      <div className="parent">
                        <img src={Wave0} className="image0" />
                        <img src={Wave} className="image1" />
                        <img src={Sound} className="image2" />
                      </div>
                      
                    </div>
                    <div style = {{textAlign:'center'}}>
                      <h3 style = {{fontSize:"1.25rem", fontFamily:'Para Font'}}>Audio File/ Real time audio</h3>
                      </div>
                  </div>
                </div>
              </Col>
              <Col xs={24}  sm={window.innerWidth > 1024 ? 8 : 24}>
                <div className="processing">
                  <div className="desc">
                    <section class="container">
                      <div class="center-div"></div>

                      <div class="choices">
                        <div class="left-top">
                          <div class="left-top-text">
                            <h3 style = {{fontSize:'1rem', fontFamily:"Para Font", fontWeight:'bolder', color:'black'}}>Sentiment Analysis</h3>
                            <ul>
                              <li>Tonal<br/> Analysis</li>
                            </ul>
                          </div>
                        </div>

                        <div class="right-top">
                          <div class="right-top-text">
                            <h3 style = {{fontSize:'1rem', fontFamily:"Para Font", fontWeight:'bolder', color:'black'}}>Speaker Diarization</h3>
                            <ul>
                              <li>Speaker identification</li>
                            </ul>
                          </div>
                        </div>

                        <div class="right-bottom">
                          <div class="right-bottom-text">
                            <h3 style = {{fontSize:'1rem', fontFamily:"Para Font", fontWeight:'bolder', color:'black'}}>Text Analytics</h3>
                            <ul>
                              <li>Entity Recognition</li>
                            </ul>
                          </div>
                          
                        </div>

                        <div class="left-bottom">
                          <div class="left-bottom-text">
                            <h3 style = {{fontSize:'1rem', fontFamily:"Para Font", fontWeight:'bolder', color:'black'}}>Speech to text</h3>
                            <ul style = {{paddingLeft:'60%'}}>
                              <li>Improved accuracy</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </Col>
              <Col xs={24}  sm={window.innerWidth > 1024 ? 8 : 24}>
                <div className="output">
                  <div className="desc">
                    <div className ="imageCont1">
                      <img src = {Dash}/>
                    </div>
                    <div style = {{textAlign:'center'}}>
                      <h3 style = {{fontSize:"1.25rem", fontWeight:"bolder"}}>Dashboard</h3>
                      <h3 style = {{fontSize:"1.25rem", fontFamily:'Para Font'}}>Seamlessly integrate the output with <br/>your existing software</h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="customerAnalytics">
        <div className="container">
          <div className="customer1">
            <div className="title"></div>
            <div className="content">
              <Row>
                <Col xs={24} sm={12}>
                  <div className="text">
                    <h1>Customer Analytics</h1>
                    <p>
                      At ListenAI, we transform the customer journey with the
                      help of Artificial intelligence, bringing the power of data
                      to help increase the efficiency of contact center to your
                      organization
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="imageParent">
                    <div className="image">
                      <img src={First} className="customerPic" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="statistics" style = {{background:'#F3F5F9', color:'black'}}>
          <div className="numbers">
            <div className="content">
              <Row>
                <Col xs={24} sm={8}>
                  <div className="icon">
                    <MoneyCollectOutlined style = {{color:'black'}} />
                  </div>
                  <div className="point">
                    <p style = {{color:'black'}}>25%<br/> Cost Saving</p>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="icon">
                    <FieldTimeOutlined style = {{color:'black'}}  />
                  </div>
                  <div className="point">
                    <p style = {{color:'black'}}>30%<br/>Optimizing time spent by agents</p>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="icon">
                    <ArrowUpOutlined style = {{color:'black'}}  />
                  </div>
                  <div className="point">
                    <p style = {{color:'black'}}>70%<br/>Increase in productivity</p>
                  </div>
                </Col>
              </Row>
            </div>
        </div>
      </div>
      <div className="trial">
        <div className="container">
          <div className="heading">
            <h1>Loved the product, try it now for free!!</h1>
          </div>
          <div className="inAndOut">
            <Row>
              <Col xs={24} sm={12}>
                <div className="input">
                  <div style={{ textAlign: "center" }}>
                    <h1>Input</h1>
                  </div>
                  <div style={{ fontSize: "1rem" }}>
                    <Form
                      name="validate_other"
                      {...formItemLayout}
                      form={form}
                      onFinish={onFinish}
                      layout="vertical"
                      initialValues={{
                        "input-number": 3,
                        "checkbox-group": ["A", "B"],
                        rate: 3.5,
                      }}
                      scrollToFirstError
                      requiredMark={false}
                    >
                      <Form.Item label="Input Type">
                        <Radio.Group name="medium">
                          <Radio
                            value="Microphone"
                            onClick={startListening}
                            className="radioInputs"
                          >
                            <span style={{ fontSize: "1.2rem" }}>
                              Microphone
                            </span>
                          </Radio>
                          <Radio
                            value="File Upload"
                            onClick={onFile}
                            className="radioInputs"
                          >
                            <span style={{ fontSize: "1.2rem" }}>
                              File Upload
                            </span>
                          </Radio>
                        </Radio.Group>
                        <div style={{ fontSize: "1rem" }}>
                          Microphone: {listening ? "on" : "off"}
                        </div>
                      </Form.Item>
                      <div className="lang">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please select language!",
                            },
                          ]}
                          label="Language"
                        >
                          <Select
                            suffixIcon={
                              <DownOutlined className="ant-select-suffix" />
                            }
                          >
                            <Option value="English">
                              <span style={{ fontSize: "1.2rem" }}>
                                English (for trial verison)
                              </span>
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="diarization">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please select speaker diarization!",
                            },
                          ]}
                        >
                          <label>Speaker Diarization</label>
                          <Select
                            suffixIcon={
                              <DownOutlined className="ant-select-suffix" />
                            }
                            disabled
                          >
                            <Option value="off">
                              <span style={{ fontSize: "1.2rem" }}>Off</span>
                            </Option>
                            <Option value="on">
                              <span style={{ fontSize: "1.2rem" }}>On</span>
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="speakers">
                        <Form.Item label="Speakers">
                          <Form.Item name="input-number" noStyle>
                            <Input suffix="Speakers" disabled />
                          </Form.Item>
                        </Form.Item>
                      </div>
                      <div className="dragBox">
                        <Form.Item label="Dragger">
                          <Form.Item
                            label="Dragger"
                            name="dragger"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            noStyle
                          >
                            <Upload.Dragger
                              name="files"
                              action="/upload.do"
                              className="dragger"
                              disabled
                            >
                              <div>
                                <p className="ant-upload-text">
                                  Drag file to this area to upload
                                </p>
                                <Upload
                                  name="logo"
                                  action="/upload.do"
                                  listType="picture"
                                >
                                  <UploadOutlined
                                    className="ant-select-suffix"
                                    style={{ fontSize: "2.5rem" }}
                                  />
                                </Upload>
                              </div>
                            </Upload.Dragger>
                          </Form.Item>
                        </Form.Item>
                      </div>
                    </Form>
                    <div className="btnPart">
                      <Button block className="demo1" onClick={submit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="output">
                  <div>
                    <h1>Output</h1>
                  </div>
                  <textarea className="outputArea"></textarea>
                </div>
              </Col>
              <Col xs={24}>
                <div style={{ textAlign: "center" }}>
                  <Button className="demo2">
                    <img src="https://img.icons8.com/fluency/20/000000/unlock-2.png" />{" "}
                    Unlock to get full experience
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <ProductOffer />
      <FooterSec />
      <GoToTop />
    </div>
  );
};
export default Product;
