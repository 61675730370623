import React from 'react';
import GoToTop from '../External/GoToTop';
import FooterSection from '../FooterSection';
import Nav from '../Nav/index';
const Privacy = (props) => {
    return (
        <div>
            <div >
                <Nav />
            </div>
            <div style={{ paddingTop: '2%' }}></div>

            <article>
                <div className = "topHeading">
                <h1>
                    Privacy Policy
                </h1>
                </div>
                <date></date>
                <p>ListenAI operates listenai.in website, which provides the “Services” (the
                    platform augmenting agents and quality assurance team performance using
                    AI)</p>
                <p>
                    This page is used to inform website visitors regarding our policies with the
                    collection, use, and disclosure of Personal Information if anyone decided to
                    use our Service, or sign up on the ListenAI website or subscribe to our
                    newsletter.

                </p>
                <p>If you choose to sign up or use our Service, then you agree to the collection
                    and use of information in relation with this policy. The Personal Information
                    that we collect is used for providing and improving the Service. We will not
                    use or share your information with anyone except as described in this Privacy
                    Policy.
                </p>
                <hr class="article-divider" />
                <h3>
                    Information Collection and Use
                </h3>
                <date></date>
                <p>For a better experience while using our Service, we may require you to
                    provide us with certain personally identifiable information, including but not
                    limited to your name, email address, and phone number. The information that
                    we collect will be used to contact or identify you.</p>
                <span>
                    <h4>Log Data</h4>
                    <p>We want to inform you that whenever you visit our Service, we collect
                        information that your browser sends to us that is called Log Data. This Log
                        Data may include information such as your computer’s Internet Protocol
                        (“IP”) address, browser version, pages of our Service that you visit, the time
                        and date of your visit, the time spent on those pages, and other statistics.</p>


                </span>
                <span>
                    <h4>Cookies</h4>
                    <p>Cookies are files with a small amount of data that is commonly used as an
                        anonymous unique identifier. These are sent to your browser from the website
                        that you visit and are stored on your computer’s hard drive.
                        Our website uses these “cookies” to collect information and to improve our
                        Service. We do not use cookies to store any personally identifiable
                        information. If you would prefer not to accept session cookies, please turn
                        them off in your browser. If you disable cookies, the features of the Service
                        that require the user to be logged in will not function with cookies disabled.
                    </p>
                </span>
                <span>
                    <h4>Service Providers</h4>
                    <p>We may employ third-party companies and individuals due to the following
                        reasons:
                        <ul>
                            <li><p>To facilitate our Service</p></li>
                            <li><p>To provide the Service on our behalf</p></li>
                            <li><p>To perform Service-related services</p></li>
                            <li><p>To assist us in analyzing how our Service is used</p></li>
                        </ul>
                    </p>
                    <p>We want to inform our Service users that these third parties have access to
                        your Personal Information. The reason is to perform the tasks assigned to
                        them on our behalf. However, they are obligated not to disclose or use the
                        information for any other purpose.
                    </p>
                </span>
                <hr className="article-divider" />
                <h3>
                    Security
                </h3>
                <date></date>
                <p>We value your trust in providing us your Personal Information, thus we are
                    striving to use commercially acceptable means of protecting it. But remember
                    that no method of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot guarantee its absolute
                    security.
                </p>
                <hr className="article-divider" />
                <h3>
                    Links to other sites
                </h3>
                <date></date>
                <p>Our Service may contain links to other sites. If you click on a third-party link,
                    you will be directed to that site. Note that these external sites are not operated
                    by us. Therefore, we strongly advise you to review the Privacy Policy of these
                    websites. We have no control over, and assume no responsibility for the
                    content, privacy policies, or practices of any third-party sites or services
                </p>
                <hr className="article-divider" />
                <h3>
                    Changes to this privacy policy
                </h3>
                <date></date>
                <p>We may update our Privacy Policy from time to time. Thus, we advise you to
                    review this page periodically for any changes. We will notify you of any
                    changes by posting the new Privacy Policy on this page. These changes are
                    effective immediately, after they are posted on this page.
                </p>
                <hr className="article-divider" />
                <h3>
                    Contact Us
                </h3>
                <date></date>
                <p>If you have any questions or suggestions about our Privacy Policy, do not
                    hesitate to contact us at <a href="#" style={{ color: "blue" }}>contact@listenai.in</a>.
                </p>
            </article>

            <div style={{ paddingTop: '2%' }}></div>

            <FooterSection />
            <GoToTop />
        </div>
    )
}
export default Privacy;