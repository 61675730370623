import React from "react";
import HomeContent from "../components/HomeContent/index";

const Home = () => {
  return (
    <HomeContent/>
  );
};

export default Home;
