import Home from './containers/Home';

import "antd/dist/antd.css";
import "./scss/main.scss";
import {BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import AboutPage from './containers/Aboutus';
import Resource from './containers/Resources';
import Blog1 from './components/Blogs/Blog1';
import Blog2 from './components/Blogs/Blog2';
import Reg from './containers/Signup';
import Log from './containers/Login';
import ProductPage from './containers/Product';
import Privacy from './components/Blogs/PrivacyTerms';
import Terms from './components/Blogs/Conditions';
function App() {
  return (
    <Router>
      <Route exact path = "/" component= {Home}/>
      <Route exact path = "/about" component = {AboutPage}/>
      <Route exact path = "/resource" component = {Resource}/>
      <Route exact path = "/blog1" component = {Blog1}/>
      <Route exact path = "/blog2" component = {Blog2}/>
      <Route exact path = "/signup" component = {Reg}/>
      <Route exact path = "/login" component = {Log}/>
      <Route exact path = "/product" component = {ProductPage}/>
      <Route exact path = "/privacy" component = {Privacy}/>
      <Route exact path = "/terms" component = {Terms}/>
    </Router>
  );
}

export default App;
